import {
  createComponentAdmin,
  OptionalString,
  PublishesTo,
  SubscribesTo,
  type SchemaTypeHelper,
} from '@backstage-components/base';
import {Type, type Static} from '@sinclair/typebox';

const reactName = 'OpenLogin';
const name = 'Open Login Authentication';
const description =
  'Allow guests to authenticate by providing an email address and their name';

const schema = Type.Object(
  {
    agreementText: OptionalString({
      title: 'Text to display with the agreement checkbox',
      default: 'I agree to recieve marketing communications',
    }),
    showAgreementCheckbox: Type.Optional(
      Type.Boolean({
        title: 'Show agreement text with checkbox',
        default: false,
      })
    ),
    buttonLabel: OptionalString({
      title: 'Submit Button Label',
      default: 'SUBMIT',
    }),
    nameInputPlaceholder: OptionalString({
      title: 'Name Input Placeholder Text',
      default: 'Enter your full name',
    }),
    emailInputPlaceholder: OptionalString({
      title: 'Email Input Placeholder Text',
      default: 'Enter email address',
    }),
    // Whether email is required is tightly coupled to the GQL resolver definition
    // for `verifyOpenLogin`. Changes to the schema may require changes to the
    // resolver be made in a backwards compatible way (because some data with the
    // old structure may already be published)
    isEmailRequired: Type.Optional(
      Type.Boolean({
        title: 'Email Required?',
        description:
          'If email is not required guests can "authenticate" with just a name',
        default: true,
      })
    ),
    // custom validation message support
    emailRequiredErrorMessage: OptionalString({
      title: 'Email Required Error Message',
      description:
        'Message displayed during authentication if an email address is not provided',
      default: 'Email must be provided',
    }),
  },
  {
    dependencies: {
      showAgreementCheckbox: {
        oneOf: [
          {
            properties: {
              showAgreementCheckbox: {
                const: true,
              },
              isCheckboxRequired: Type.Optional(
                Type.Boolean({
                  title: 'Is Checkbox Required',
                  description: 'Whether the checkbox is required.',
                  default: false,
                })
              ),
            },
          },
          {
            properties: {
              showAgreementCheckbox: {
                const: false,
              },
            },
          },
        ],
      },
    },
  }
);

const uiSchema = {
  'ui:groups': {
    'ui:template': 'tabs',
    sections: [
      [
        'Properties',
        [
          {
            'ui:template': 'accordion',
            sections: [
              [
                'General',
                [
                  'nameInputPlaceholder',
                  'isEmailRequired',
                  'emailInputPlaceholder',
                  'showAgreementCheckbox',
                  'isCheckboxRequired',
                  'agreementText',
                  'buttonLabel',
                ],
              ],
              ['Error Messages', ['emailRequiredErrorMessage']],
            ],
          },
        ],
      ],
      [
        'Styling',
        [
          {
            'ui:template': 'accordion',
            sections: [['Custom Styles', ['styleAttr']]],
          },
        ],
      ],
      [
        'Animations',
        [
          {
            'ui:template': 'accordion',
            sections: [['Animations', ['animationStates']]],
          },
        ],
      ],
    ],
  },
  titleColor: {'ui:widget': 'color'},
  subtitleColor: {'ui:widget': 'color'},
  agreementText: {
    'ui:widget': 'modalTextareaWidget',
    'ui:options': {
      buttonTitle: 'Content',
      editor: 'TinyMCE',
      /**
       * Hard coding this api key as a stop gap since TinyMCE has other
       * safeguards in place around which domains can be whitelisted to display
       * the rich text editor. Planning to remove this api key as part of
       * https://lcdigital.atlassian.net/browse/DLB-1402
       */
      tinyApiKey: 'mb6fyq4wtavfomcdjm3guo04keatf4msk5b5sd1p81pvtdrw',
    },
  },
};

type DefaultFieldData = Static<typeof schema>;

const defaultFieldData: DefaultFieldData = {
  agreementText: schema.properties.agreementText.default,
  buttonLabel: schema.properties.buttonLabel.default,
  emailInputPlaceholder: schema.properties.emailInputPlaceholder.default,
  nameInputPlaceholder: schema.properties.nameInputPlaceholder.default,
  showAgreementCheckbox: schema.properties.showAgreementCheckbox.default,
  isEmailRequired: schema.properties.isEmailRequired.default,
  emailRequiredErrorMessage:
    schema.properties.emailRequiredErrorMessage.default,
};

const instructions = Type.Union([
  PublishesTo({
    topic: `${reactName}:verify`,
    description: 'Requests the given name & email be verified for the show',
    meta: {
      agreementAnswer: Type.Boolean({
        description: 'Whether the agreement checkbox was selected',
      }),
      agreementText: Type.String({
        description: 'Text to which the agreement checkbox corresponded',
      }),
      email: Type.Optional(
        Type.String({
          description: 'email to be verified',
          format: 'email',
        })
      ),
      showId: Type.String({
        description:
          'Unique identifier for the show against which authentication will be checked',
      }),
      name: Type.String({
        description: 'Name to be displayed in chat',
      }),
      moduleId: Type.String({
        description: 'Unique identifier of the module performing verification',
      }),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  SubscribesTo({
    topic: `${reactName}:success`,
    description: 'Access code was successfully verified',
    meta: {
      attendee: Type.Object({
        id: Type.String({
          description: 'Unique identifier for the verified Attendee',
        }),
        name: Type.String({
          description: 'Name of the attendee, if known',
        }),
        email: Type.Union([Type.Null(), Type.String()], {
          description: 'Attendee email address if known, null otherwise',
        }),
        chatTokens: Type.Array(
          Type.Object({
            token: Type.String({
              description: 'Token used to authenticate with getstream API',
            }),
          })
        ),
        tags: Type.Array(Type.String(), {
          description: 'Tags associated with the attendee',
        }),
      }),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  SubscribesTo({
    topic: `${reactName}:failure`,
    description: 'Access code could not be verified',
    meta: {
      reason: Type.Optional(
        Type.String({
          description: 'Indicates the reason the code could not be verified',
        })
      ),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-failure`,
    description: 'Indicates an unsuccessful verification has occurred.',
    meta: {
      reason: Type.Optional(
        Type.String({
          description: 'Indicates the reason the code could not be verified',
        })
      ),
      showId: Type.String({
        description:
          'Unique identifier for the show against which Attendee was unable to be verified.',
      }),
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-success`,
    description: 'Indicates a successful verification has occurred.',
    meta: {
      attendeeId: Type.String({
        description: 'Unique identifier for the verified Attendee',
      }),
      attendeeName: Type.String({
        description: 'Attendee name if available, null otherwise',
      }),
      attendeeEmail: Type.Union([Type.Null(), Type.String()], {
        description: 'Attendee email address if available, null otherwise',
      }),
      attendeeTags: Type.String({
        description:
          'Comma separated list of tags associated with the attendee',
      }),
      showId: Type.String({
        description:
          'Unique identifier for the show against which Attendee was verified.',
      }),
    },
  }),
]);

export const ComponentDefinition = createComponentAdmin({
  id: '45eef1bc-e1d9-42e3-a1cb-ec5a59dfe71a',
  reactName,
  name,
  category: 'preset',
  description,
  defaultFieldData,
  instructions,
  schema,
  slotConfiguration: {},
  slug: reactName,
  uiSchema,
  version: 1,
})
  .withAnalyticsInstructionMask((instruction) => {
    switch (instruction.type) {
      case 'OpenLogin:on-success':
        return {
          type: 'OpenLogin:on-success',
          meta: {about: instruction.meta.about},
        };
      case 'OpenLogin:success':
        return {
          type: 'OpenLogin:success',
          meta: {about: instruction.meta.about},
        };
      case 'OpenLogin:verify':
        return {
          type: 'OpenLogin:verify',
          meta: {
            about: instruction.meta.about,
            agreementAnswer: instruction.meta.agreementAnswer,
            moduleId: instruction.meta.moduleId,
            showId: instruction.meta.showId,
          },
        };
      default:
        return instruction;
    }
  })
  .withAnimationStates()
  .withStyles()
  .build();

export type SchemaType = SchemaTypeHelper<typeof ComponentDefinition>;
